import { Component, HostListener, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Toast } from './core/utils/toast.util';
import { environment } from 'src/environments/environment';
import { of, delay } from 'rxjs';
import { CustomTranslateService } from './core/services/custom-translate.service';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from './core/models/interfaces/common';
import { Permission } from './core/models/interfaces/permission';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, Toast],
})
export class AppComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  environment = environment;
  environmentURL?: string = environment.FE_URL_SALE;
  sanitizer = inject(DomSanitizer);
  shopURL?: string;
  iframeUrl: SafeResourceUrl;

  stateView: string = STATE_RENDER_APP.INIT;
  user: any;
  isLoading = false;

  constructor(
    private primeNGConfig: PrimeNGConfig,
    private messageService: MessageService,
    private translator: CustomTranslateService,
    private httpClient: HttpClient,
  ) {}

  @HostListener('window:message', ['$event'])
  handleReceiveMessage(e: MessageEvent) {
    if (e?.data?.type === 'authorized') {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));
        this.isLoading = false;
        this.user = profileData;
        this.user.avatarURL = `${environment.BE_URL}storage/files/web/${this.user?.avatar_thumbnail_url}.webp`;
        this.onCheckPermission();
      } else {
        window.location.href = `${environment.ADMIN_HUB_URL}/login`;
      }
    }
  }

  ngOnInit(): void {
    this.primeNGConfig.setTranslation({
      firstDayOfWeek: 1,
    });

    this.shopURL = `${this.environmentURL}shopping`;
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.ADMIN_HUB_URL}/app-connect`,
    );

    of(true)
      .pipe(delay(5000))
      .subscribe(() => {
        if (this.isLoading) {
          this.messageService.add({
            severity: 'warn',
            detail: this.translator.sentenceCase(
              'common.you-need-super-admin-permissions',
            ),
          });
          setTimeout(() => {
            window.location.href = `${environment.ADMIN_HUB_URL}/login`;
          }, 2000);
        }
      });
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };

  onCheckPermission() {
    this.httpClient
      .get<ResponseData<Permission[]>>(
        `${environment.BE_URL}user/admin/roles/module`,
      )
      .subscribe({
        next: (res) => {
          const permissions = res.data ?? [];
          const isHavePermission = permissions.find(
            (permission) =>
              permission.role === 'SUPER_ADMIN' || permission.role === 'ADMIN',
            // &&
            //   permission.type_moderator === 'VOUCHER_MODULE_ADMIN'),
          );
          if (isHavePermission) {
            this.stateView = STATE_RENDER_APP.APP;
          } else {
            window.location.href = `${environment.ADMIN_HUB_URL}`;
          }
        },
        error: () => {
          window.location.href = `${environment.ADMIN_HUB_URL}`;
        },
      });
  }
}
