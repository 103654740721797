import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  DateFormatPipe,
  DatetimeFormatterModule,
} from '@soctrip-common/datetime-formatter';
import { ClipboardModule } from 'ngx-clipboard';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TreeSelectModule } from 'primeng/treeselect';
import { CoreModule } from '../core/core.module';
import { Countries } from '../core/models/classes/countries';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { ShortNumberPipe } from '../core/pipes/short-number.pipe';
import { AlphaNumericDirective } from '../directives/alpha-numeric.directive';
import { DragAndDropDirective } from '../directives/drag-and-drop.directive';
import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { CustomPreviewVoucherComponent } from './components/custom-preview-voucher/custom-preview-voucher.component';
import { CustomUploadImgComponent } from './components/custom-upload-img/custom-upload-img.component';
import { DeliveryInformationComponent } from './components/delivery-information/delivery-information.component';
import { FlagsDropdownComponent } from './components/dropdowns/flags-dropdown/flags-dropdown.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { HeaderComponent } from './components/header/header.component';
import { OrderInformationComponent } from './components/order-information/order-information.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PanelComponent } from './components/panel/panel.component';
import { PaymentInformationComponent } from './components/payment-information/payment-information.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { RefundProductComponent } from './components/refund-product/refund-product.component';
import { RequestStatusDetailComponent } from './components/request-status-detail/request-status-detail.component';
import { SelectTimeComponent } from './components/select-time/select-time.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { TabViewComponent } from './components/tabview/tabview.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import { TimeNumberDirective } from '../directives/only-number.directive';
@NgModule({
  declarations: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    FormSectionComponent,
    DragAndDropDirective,
    PaginatorComponent,
    CustomUploadImgComponent,
    CustomPreviewVoucherComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    AdminLayoutComponent,
    PanelComponent,
    ShortNumberPipe,
    ProductListComponent,
    DeliveryInformationComponent,
    PaymentInformationComponent,
    OrderInformationComponent,
    OrderStatusComponent,
    RequestStatusDetailComponent,
    RefundProductComponent,
    SelectTimeComponent,
    WelcomeLayoutComponent,
    CalendarComponent,
    TimeNumberDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CoreModule,
    ButtonModule,
    FileUploadModule,
    ImageModule,
    SidebarModule,
    TableModule,
    DialogModule,
    PaginatorModule,
    TreeSelectModule,
    InputTextModule,
    TranslateModule,
    ToastModule,
    SkeletonModule,
    DialogModule,
    OverlayPanelModule,
    ClipboardModule,
    CalendarModule,
    ProgressSpinnerModule,
    DatetimeFormatterModule,
  ],
  exports: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    TranslateModule,
    FormSectionComponent,
    DragAndDropDirective,
    SentenceCasePipe,
    PaginatorComponent,
    CustomUploadImgComponent,
    CustomPreviewVoucherComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    PanelComponent,
    ShortNumberPipe,
    DeliveryInformationComponent,
    ProductListComponent,
    PaymentInformationComponent,
    OrderInformationComponent,
    OrderStatusComponent,
    RequestStatusDetailComponent,
    RefundProductComponent,
    SelectTimeComponent,
    DateFormatPipe,
    CalendarComponent,
    PaginatorModule,
    TimeNumberDirective,
  ],
  providers: [Countries],
})
export class SharedModule {}
